:root {
  --css-Primary: #1298be;
  --css-Info: #1298be;
  --css-Secondary: #6f809e;
  --css-Success: #89d888;
  --css-Warning: #f0b61d;
  --css-Danger: #ed3451;
  --css-Link: #24b0c3;
  --css-Dark: #1f1f1f;
  --css-Light: #ffffff;
  --css-Gray: #878a99;
  --css-DarkBlue: #03263c;
  --css-Placeholder: #c4c3c3;

  --css-font-family: 'Noto Sans Thai', sans-serif;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--css-font-family);
}

.container {
  width: 1100px !important;
}
.table th {
  font-weight: 500;
}
[data-layout='vertical'][data-sidebar='dark'] .navbar-menu {
  background: var(--css-DarkBlue);
}
.border-left {
  border-left: 1px solid var(--css-Placeholder);
  border-radius: 0;
  height: 30px;
}
.text-Placeholder {
  color: var(--css-Placeholder) !important;
}

.text-Primary {
  color: var(--css-Primary) !important;
}
.text-Secondary {
  color: var(--css-Secondary) !important;
}
.text-Success {
  color: var(--css-Success) !important;
}
.text-Danger {
  color: var(--css-Danger) !important;
}
.text-Dark {
  color: var(--css-Dark) !important;
}
.text-Gray {
  color: var(--css-Gray) !important;
}
.link-trash {
  color: var(--css-Gray);
  cursor: pointer;
}
.link-trash:hover,
.link-trash:active,
.link-trash:focus {
  color: var(--css-Danger);
  cursor: pointer;
}
.link-edit {
  color: var(--css-Gray);
  cursor: pointer;
}
.link-edit:hover,
.link-edit:active,
.link-edit:focus {
  color: var(--css-Primary);
  cursor: pointer;
}
a:hover {
  background-color: unset !important;
}

body {
  margin: 0;
  font-family: var(--css-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.navbar-menu .navbar-nav .nav-link {
  font-size: 0.9375rem;
  font-family: var(--css-font-family);
}
code {
  font-family: var(--css-font-family);
}

.float-left {
  float: left;
}
.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.mb-n15 {
  margin-bottom: -9rem !important;
}

.pt-10 {
  padding-top: 6rem !important;
}

.pt-15 {
  padding-top: 9rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 6rem !important;
}

.big-title {
  font-size: 48px;
}
.big-subtitle {
  font-size: 24px;
}
.text-16 {
  font-size: 16px;
}
.text-14 {
  font-size: 14px;
}
.text-12 {
  font-size: 12px;
}
.text-8 {
  font-size: 8px;
}
.font-weight-bold {
  font-weight: bold;
}
.auth-bg-cover {
  background: #032c46;
}

/* .btn-Primary {
  color: var(--css-Light);
  background-color: var(--css-Primary);
  border: 0;
} */
/* .btn-Primary:hover,
.btn-Primary:active,
.btn-Primary:focus {
  color: var(--css-Light);
  background-color: var(--css-Primary);
  border: 0;
  opacity: 0.9;
} */
.card-table-fix {
  min-height: 450px;
  height: auto;
  overflow-y: auto;
}
.card-table-fix-lg {
  min-height: 550px;
  height: auto;
  overflow-y: auto;
}
.footer-login {
  bottom: 0;
  padding: 20px calc(1.5rem / 2);
  position: absolute;
  right: 0;
  color: var(--css-Light);
  left: 250px;
  height: 50px;
  background-color: transparent;
}
.footer-page {
  bottom: 10px;
  padding: 20px calc(1.5rem / 2);
  position: absolute;
  right: 0;
  color: var(--css-Light);
  left: 250px;
  height: 40px;
  background-color: transparent;
}
.footer {
  bottom: 0;
  padding: 10px calc(1.5rem / 2);
  right: 0;
  color: var(--css-Secondary);
  left: 250px;
  height: 30px;
  background-color: #dbe1e5;
  z-index: 9;
}
.invalid-feedback-err {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--css-Danger);
}
.form-control.is-invalid,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid {
  border-color: var(--css-Danger);
  padding-right: calc(1.5em + 1rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-image: none;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
#password-contain p.invalid {
  color: var(--css-Gray);
}

.d-flex-end {
  justify-content: flex-end;
}

.alert.alert-danger .btn-close::after {
  color: unset;
}
.xs-alert-center {
  /* width: 23%; */
  text-align: left;
  margin: 40px auto;
  left: 45% !important;
}
.sm-alert-center {
  width: 55%;
  text-align: left;
  margin: 40px auto;
}
.alert.alert-success .btn-close::after {
  color: #000000;
}
.text-right {
  text-align: right;
}
.cursor-pointer {
  cursor: pointer;
}
.ReactModal__Overlay {
  opacity: 0;
  /* transform: translateX(-100px); */
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  /* transform: translateX(0px); */
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  /* transform: translateX(-100px); */
}
/* .modal-body {
  border-top: 1px solid #ced4da;
  margin-top: 8px;
} */
.alert {
  z-index: 9999;
  position: fixed;
  left: 25%;
}
.scroll-content-500 {
  height: 575px;
  overflow-y: auto;
}
a {
  cursor: pointer;
}
a:hover {
  color: #2f8ca6 !important;
  opacity: 0.9;
  background-color: unset;
}

.pr-1 {
  padding-right: 10px;
}
.pr-2 {
  padding-right: 20px;
}
.pr-3 {
  padding-right: 30px;
}
.pr-4 {
  padding-right: 40px;
}
.pr-5 {
  padding-right: 50px;
}

/* .card {
  min-height: 525px;
} */

/* .zero-center {
  width: 100%;
  padding: 7% 30%;
  text-align: center;
  position: absolute;
} */
.zero {
  width: 100%;
  text-align: center;
  position: absolute;
}
.img-sm-px {
  width: 15px;
}

.transform1 {
  transform: translate(0%, 0px) scale(0.260743, 1);
}
.transform2 {
  transform: translate(100.0743%, -6px);
  z-index: 4;
}

.w-30 {
  width: 30%;
}
.noUi-handle {
  background: var(--css-Primary);
  border: 2px solid var(--vz-card-bg);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.accordion .accordion-button {
  font-weight: 600;
}
.w-120px {
  width: 130px;
}
.w-250px {
  width: 250px;
}
.noUi-target {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--css-Primary);
  border-color: var(--vz-light);
}
.react_color {
  width: 30px;
  height: 30px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.sketch-picker {
  position: absolute;
  z-index: 99999;
}
.color-input {
  width: 90px;
  margin-left: 17px;
  height: 30px;
}
.rangeslider-horizontal {
  height: 4px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.rangeslider__fill {
  background-color: #1298be !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.rangeslider__handle:after {
  display: none !important;
}
.rangeslider__handle {
  width: 15px !important;
  height: 15px !important;
  box-shadow: unset !important;
  border: 1px solid #fff !important;
  background: #1298be !important;
}
.popover {
  position: absolute;
  z-index: 9999999;
}
.pop_cover {
  /* position: fixed; */
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.p-25-center {
  padding: 25% 3rem;
}
input[type='range'] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100px;
  height: 4px;
  background: #9fd5e5;
  background-image: linear-gradient(#1298be, #1298be);
  background-repeat: no-repeat;
}
/* Input Thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #1298be;
  cursor: ew-resize;
  border: 1px solid #fff;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #1298be;
  cursor: ew-resize;
  border: 1px solid #fff;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
}
input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #1298be;
  cursor: ew-resize;
  border: 1px solid #fff;
  box-shadow: none;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
  background: #1298be;
}

input[type='range']::-moz-range-thumb:hover {
  background: #1298be;
}

input[type='range']::-ms-thumb:hover {
  background: #1298be;
}

/* Input Track */
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.card-table-sm-fix {
  min-height: 400px;
  height: auto;
  overflow-y: auto;
}

.device-frame {
  position: relative;
  text-align: center !important;
}
.text-left {
  text-align: left;
}

.p-5per {
  padding: 2% 0;
}

.footer-banner {
  justify-content: flex-start;
  padding: 5px;
  background: #8a8b8c1c;
  color: #aabaca;
}

.-cate-active::after {
  filter: invert(100%) sepia(6%) saturate(24%) hue-rotate(142deg) brightness(106%) contrast(106%) !important;
}
.accordion-button:hover {
  background-color: #e8f5f9;
}

.accordion-button.transform-90::after {
  transform: rotate(-90deg) !important;
}

/* .sketch-picker > div:nth-child(2) {
  display: none !important;
} */
.sketch-picker > div:nth-child(3) {
  display: none !important;
}
.border-bottom-dotted-gray {
  border-bottom: 1px dotted #e9ebec;
}
.modal-fullscreen {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.bg-device {
  background-image: url(../../public/assets/images/wirefram-desktop.jpg);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

textarea {
  resize: none;
}
.cookie-full-preview {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.max-w-1040 {
  max-width: 1014px !important;
}
.input-white {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
}
.fixed-magnify-icon {
  position: absolute;
  right: 250px;
  z-index: 999;
  top: 11px;
  opacity: 0.4;
  font-size: 16px;
}
.fixed-x-icon {
  position: absolute;
  right: 10px;
  z-index: 999;
  top: 11px;
  opacity: 0.4;
  font-size: 16px;
}

.flatpickr-input {
  background-color: #fff !important;
  border: 1px solid #ced4da !important;
  border-radius: 5px 0px 0px 5px !important;
  padding: 0.5rem 0.9rem !important;
  width: 200px;
  font-size: 14px;
  outline: none;
}

.flatpickr-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.4; /* Firefox */
}

.input-white::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0.4; /* Firefox */
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #1298be !important;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #1298be !important;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: #1298be !important;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #1298be !important;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #1298be !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #1298be !important;
}
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #1298be !important;
  box-shadow: -10px 0 0 #1298be !important;
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #42a5f5, 5px 0 0 #1298be !important;
  box-shadow: -5px 0 0 #42a5f5, 5px 0 0 #1298be !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #1298be !important;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #1298be !important;
}
.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #1298be !important;
  color: #fff;
  fill: #fff;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.zero-center {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 18%;
}
.zero-content {
  width: 400px;
  margin: auto;
}

.card-max-fix {
  height: 588px;
}
.font-weight-normal {
  font-weight: 500;
}

.rti--tag {
  border-radius: 15px !important;
  font-size: 12px !important;
  background: #ced4da !important;
  color: #495057;
}
.text-red {
  color: #ed3451 !important;
}
.tag-red {
  color: #fff;
  background: #ed3451 !important;
}

.tag-red > button {
  color: #fff !important;
}

.rti--tag > button:hover {
  color: unset !important;
}
.rti--tag > span {
  margin-left: 5px;
}
.rti--tag > button {
  border-left: 1px solid !important;
  border-radius: 0 !important;
  margin-left: 7px;
  margin-top: 2px;
  color: #495057;
}
.rti--container {
  box-shadow: unset !important;
}
a.role-disble :hover {
  color: #878a99 !important;
  background-color: unset !important;
}
.role-disble {
  color: var(--css-Gray);
}
.card-pk {
  border: 1px solid #00000038 !important;
  box-shadow: 3px 2px 6px 1px rgb(56 65 74 / 20%) !important;
}
.badge-pk {
  position: absolute;
  margin-top: 10px;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

.border-dashed-pk {
  border-bottom: 1px dashed #0000004a;
}
.disable-div {
  background: #fff;
  opacity: 0.3;
  cursor: no-drop;
}
.lightBlue {
  color: #59a1c1;
}

.dropzone {
  min-height: unset;
  border: 2px dashed #1298be;
  background: var(--vz-card-bg);
  border-radius: 6px;
  height: auto;
  cursor: pointer;
}
.img-logo-footer {
  max-width: 100%;
  max-height: 30px;
}
.box-preview-mini-cookie {
  position: absolute;
  left: 10px;
  bottom: 15px;
  width: 46px;
  height: 46px;
  text-align: center;
  padding: 5px;
}
.preview-mini-cookie {
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid #ced4da69;
  box-shadow: 1px 1px 3px 1px rgb(56 65 74 / 16%);
}

#cookie-fade {
  animation: cookie-fade 0.5s 1;
  -webkit-animation: cookie-fade 0.5s 1;
  animation-fill-mode: forwards;

  animation-delay: 20s;
  -webkit-animation-delay: 20s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}

@keyframes cookie-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes cookie-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.-cursor {
  cursor: pointer;
}
.text-blue {
  color: #1298be;
}

.badge-Free {
  color: #ffffff;
  background-color: #c4c3c3;
}
.badge-light-blue {
  color: #1298be !important;
  background-color: #dbf7ff;
}
.badge-Basic {
  color: #ffffff;
  background-color: #729b45;
}
.badge-Ultimate {
  color: #ffffff;
  background-color: #8d32b6;
}
.badge-Suspended {
  color: #ffffff;
  background-color: #ed3451;
}
.badge-Limit {
  color: #d11b1b;
  background-color: #fcbbbe;
}
.badge-Pro {
  color: #ffffff;
  background-color: #07419e;
}
.badge-Enterprise {
  color: #ffffff;
  background-color: #c39f2a;
}

.badge-Pending-Pro {
  color: #07419e;
  border: 1px solid #07419e;
  background-color: transparent;
  padding: 3px;
}
.badge-Pending-Suspended {
  color: #ed3451;
  border: 1px solid #ed3451;
  background-color: transparent;
  padding: 3px;
}
.badge-Pending-Basic {
  color: #729b45;
  border: 1px solid #729b45;
  background-color: transparent;
  padding: 3px;
}
.badge-Pending-Ultimate {
  color: #8d32b6;
  border: 1px solid #8d32b6;
  background-color: transparent;
  padding: 3px;
}
.badge-Complimentary {
  color: #ffffff;
  background-color: #1298be;
}

.text-gold {
  color: #c39f2a;
}

.w-160px {
  width: 160px;
}
.w-230px {
  width: 230px;
}

.w-516px {
  width: 516px;
}

.fs-30 {
  font-size: 30px;
}
.left-0 {
  left: 0 !important;
}
.package-box {
  padding: 15px;
  border-radius: 10px;
  background-color: #f3f6f9;
  height: 230px;
}
.form-icon.right img {
  left: auto;
  right: 18px;
}
.form-icon img {
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
}
.btn-gray:disabled,
.btn-gray.disabled {
  color: #ffffff;
  background-color: #c4c3c3;
  border-color: #c4c3c3;
  opacity: unset;
}
.loader {
  border-radius: 50%;
  margin: auto;
}

.loading-sm {
  width: 25px !important;
}

.card-loader {
  min-height: 500px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.FadeInAndOut {
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: fade 0.5s linear;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.min-w-auto {
  min-width: auto;
}
.none-overflow {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}

.table-dashboard {
  width: 50%;
  overflow-x: auto;
  max-width: 50px;
}
.max-content {
  width: max-content;
}

@media only screen and (max-width: 413px) {
  .footer-login {
    padding: 20px calc(1.5rem / 2);
    position: absolute;
    right: 0;
    color: var(--css-Light);
    left: 250px;
    height: 50px;
    background-color: transparent;
  }
}

@media only screen and (max-width: 991px) {
  .footer-login {
    padding: 20px calc(1.5rem / 2);
    position: absolute;
    right: 0;
    color: var(--css-Light);
    left: 250px;
    height: 50px;
    background-color: transparent;
    bottom: unset;
  }
  .auth-page-wrapper .auth-page-content {
    /* padding-bottom: 60px; */
    position: relative;
    z-index: 2;
    width: 100%;
  }
}
.menu-link > span {
  word-wrap: break-word;
  width: 175px;
}

.text-10 {
  font-size: 10px !important;
}
.max-w-550 {
  max-width: 550px;
}
.backdrop-header-popup {
  background-color: #06060696;
}
.w-52 {
  width: 52% !important;
}
.break-word {
  word-wrap: break-word;
}

.text-lightGray {
  color: #c4c3c3;
}
.MuiTooltip-tooltip {
  background-color: rgb(0, 0, 0) !important;
  width: 220px;
  font-size: 14px !important;
  border-radius: 5px;
  font-family: var(--css-font-family) !important;
}
.MuiTooltip-arrow {
  color: rgb(0, 0, 0) !important;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn:disabled {
  background-color: #ced4da;
  border-color: #ced4da;
}

.btn-light:disabled {
  background-color: #f4f6f9;
  border-color: #f4f6f9;
  color: #b3b3b3;
}
.btn:disabled .label-icon {
  background-color: #c4cbd2 !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.form-check-input:disabled {
  background-color: #ced4da;
  border-color: #ced4da;
}

.fx-form-control:disabled {
  border: 1px solid #ced4da;
  background: #fff;
  cursor: no-drop;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.d-visible {
  visibility: hidden !important;
}
.text-gray {
  color: #878a99;
}

.dropdown-toggle-up::after {
  display: inline-block;
  margin-left: 0.255em;
  font-size: 15px;
  line-height: 15px;
  content: '\f0140';
  font-family: 'Material Design Icons';
  transform: rotate(180deg);
}

.absolute-40 {
  position: absolute;
  right: 40px;
}
.show::after {
  transform: rotate(180deg);
}

.text-capitalize {
  text-transform: capitalize;
}
.box-flex-center {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}
.box-flex-between {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.box-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.txt-holder-grey::placeholder {
  color: #ced4da;
}

.fixed_header {
  position: sticky;
  top: 69px;
  z-index: 999;
}
